import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import ListItem from '@solid-ui-components/Educabot/ListItem'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/Educabot/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  items: {
    display: 'block'
  },
  title: {
    fontSize: 40,
    fontWeight: 600,
    color: '#2A205E',
    mb: '1rem',
    '@media (max-width: 765px)': {
      fontSize: 30,
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  subTitleText: {
    fontSize: '18px',
    width: '80%',
    margin: 'auto'
  },
  subTitle: {
    mt: '-1rem',
    fontSize: '18px'
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 991px)': {
      justifyContent: 'center',
      alignItems: 'baseline',
    },
    '@media (max-width: 765px)': {
      justifyContent: 'flex-start',
    },
  },
  contentText: {
    flexBasis: `2/5`,
    textAlign: [`left`, 'left', 'center', `left`],
    '@media (max-width: 765px)': {
      pt: '2rem',
    },
    '@media (max-width: 500px)': {
      textAlign: 'center',
      marginBottom: '1rem'
    },
    '@media (max-width: 545px)': {
      pt: '2rem',
    }
  },
  textContainer: {
    '@media (max-width: 500px)': {
      textAlign: 'center',
      marginBottom: '1rem'
    }
  },
}

const HeroBlock01 = ({
  content: { text = [], images, buttons, collection, icon },
  reverse
}) => (
  <Container>
    <Box sx={{ textAlign: `center`, maxWidth: '100%', pb: '2rem' }}>
      <Text sx={styles.title}> También organizamos... </Text>
      <Text sx={styles.subTitleText}>Desde Educabot, no solo ofrecemos soluciones digitales, sino también generamos eventos que promueven la cultura maker, el pensamiento computacional y lógico, además de la programación y la robótica.</Text>
    </Box>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          reverse ? 'column-reverse' : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `3/5`],
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`
        }}
      >
        <ContentImages
          content={{ images }}
          loading='eager'
          reverse={reverse}
          imagePosition='center'
        />
      </Box>

      <Box sx={styles.contentText}>
        <Reveal effect='fadeInDown'>
          <Box sx={styles.contentText}>
            <ContentText content={text} />
            <Text sx={styles.subTitle}>Arduino Day es un <strong>evento mundial</strong> que nace para conmemorar el aniversario de Arduino. Si te gusta la <strong>cultura maker</strong>, amás la <trong>robótica</trong> y sos fanático de <strong>Arduino</strong> simplemente tenés ganas de <strong>aprender</strong>, este evento es para vos.
              <p>
                <strong>¡No te pierdas ninguno de nuestros talleres, concursos, charlas y mucho más!</strong>
              </p>
            </Text>
          </Box>
        </Reveal>
        {collection && (
          <>
            <Box sx={styles.items}>
              <Reveal
                effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
                duration={1.5}
              >
                {collection.map((props, index) => (
                  <>
                    <ListItem key={`item-${index}`} {...props} />
                  </>
                ))}
              </Reveal>
            </Box>
          </>
        )}
        {buttons && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({
              mb: [4, null, null, 0],
              '@media (max-width: 500px)': {
                textAlign: 'center'
              }
            })}
          >
            {buttons && (
              <>
                <Divider space={3} />
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        )}
      </Box>
    </Flex>
  </Container>
)


export default WithDefaultContent(HeroBlock01)
