/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

 export default {
   header: {
     bg: '#27A8FF',
     pt: '5rem',
   },
   contact: {
     bg: '#27A8FF'
   },
   heroOne: {
    bg: '#FFFFFF',
    pt: '4rem',
    pb: '2rem',
    '@media (max-width: 991px)': {
      pt: '1rem'
    },
   },
   sections: {
     bg: '#FFFFFF',
     pt: '10rem'
   },
   whyChooseUs: {
     bg: '#FFFFFF',
     pt: '5rem',
     pb: '5rem'
   },
   simplify: {
     bg: '#FFFFFF',
     pt: '5rem',
     pb: '5rem'
   },
   features: {
     pt: '3rem',
     pb: '5rem'
   },
   testimonials: {
     bg: '#FFFFFF',
     pt: '8rem',
     pb: '6rem'
   },
   trustUs: {
     bg: '#F7F8FC',
     pt: '5rem',
     pb: '5rem'
   },
   blog: {
     bg: '#FFFFFF',
     pt: '5rem',
     pb: '5rem'
   },
   politics: {
    bg: '#2A205E',
    color: '#FFF',
    textAlign: 'center',
    padding: '40px',
    '& a': {
        fontWeight: 'bold',
        color: '#FFF',
    }
   }
 }