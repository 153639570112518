import React from 'react'
import Helmet from 'react-helmet'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import theme from './_theme'
import styles from './_styles'

import { Container, Box, Text, Card } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalContactSuite from '@solid-ui-blocks/Educabot/Education/Modal/Block01/Block01'
import ModalContactAulaMaker from '@solid-ui-blocks/Educabot/AulaMaker/Modal/Block01/Block01'
import ModalContactSmartTeam from '@solid-ui-blocks/Educabot/SmartTeam/Modal/Block01/Block01'
import favicon from '../../../../../site/content/assets/favicon.png'

import Header from '@solid-ui-blocks/Educabot/Header/header'
import HeroOne from '@solid-ui-blocks/Educabot/Nosotros/Hero/Block03'
import Arduino from '@solid-ui-blocks/Educabot/Nosotros/Hero/Arduino'
import CopaRobotica from '@solid-ui-blocks/Educabot/Nosotros/Hero/CopaRobotica'
import Features from '@solid-ui-blocks/Educabot/Nosotros/Features/Block05'
import Posts from '@solid-ui-blocks/Educabot/Blog/Block01'
import Testimonials from '@solid-ui-blocks/Educabot/Testimonials/Block03'
import Contact from '@solid-ui-blocks/Educabot/Hero/Block06'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'
import Block07 from '@solid-ui-blocks/Educabot/Hero/Block07'

import WithRecentPosts from '@solid-ui-blocks/WithRecentPosts'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='EDUCABOT' />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <ModalContactSuite content={content['contact-suite']} />
      <ModalContactAulaMaker content={content['contact-aula-maker']} />
      <ModalContactSmartTeam content={content['contact-smart-team']} />

      <Header content={content['header']} />

      <Box sx={styles.heroOne}>
        <HeroOne content={content['heroOneNosotros']} reverse />
      </Box>


      <Box sx={styles.features}>
        <Features content={content['features-solution']} />
      </Box>

      <Box sx={styles.heroOne}>
        <Arduino content={content['arduino']} />
      </Box>

      <Box sx={styles.heroOne}>
        <CopaRobotica content={content['copa-robotica']} reverse/>
      </Box>

      <Box sx={styles.blog}>
        <WithRecentPosts>
          <Posts content={content['posts-educabot-nosotros']} reverse />
        </WithRecentPosts>
      </Box>

      {/* <Box sx={styles.contact}>
        <Contact content={content['formContact']} reverse />
      </Box> */}

      <Box sx={styles.testimonials}>
        <Testimonials content={content['testimonials']} />
      </Box>

      <Box sx={styles.politics}>
        <p>
            Si querés conocer más sobre nuestras políticas de calidad, podés hacerlo ingresando <a href="https://docs.google.com/document/d/e/2PACX-1vT7p7KucXn7r42NQauOCC8EP6R_z51bcQupYSOu-pf36c3Ek7RplwA2p71Ahb4yOw7Q9xgFLKPdX5xq/pub" target='_blank'>aquí</a>.
        </p>
      </Box>

      <Footer content={content['footer']} />


    </Layout>
  )
}

export const query = graphql`
  query nosotrosBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
